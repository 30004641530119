import React from "react";
import Img from "gatsby-image"
import { graphql } from 'gatsby';
import Layout from "../layouts/layout";
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';


const CodeOfEthics  = ({data : {pageData, hero}}) =>  {

    hero.heroHeading = "Code of Ethics";

    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, next) => `<p>${next(node.content)}</p>`
        }
    };

    return(
        <Layout hero={hero}>
            <div className="container mx-auto mb-16 px-4">
                <div className="nested-html text-justify mx-auto sm:w-2/3"
                      dangerouslySetInnerHTML={{ __html: documentToHtmlString(JSON.parse(pageData.copy.raw), options), }}
                />
            </div>
        </Layout>
    )};

export const query = graphql`
query codeOfEthics  {
    hero: contentfulLegalPage (slug: {eq: "code-of-ethics"}) {
     heroBackgroundImage {
          resize(width: 1440) {
           src
          }
        }
    }
    pageData: contentfulLegalPage (slug: {eq: "code-of-ethics"}) {
        copy {
            raw
        }
    }}
`;


export default CodeOfEthics;
